import React, { Component } from 'react';
import { Link } from 'components/Elements';
import { Page } from 'components/Layout';
import FourOhFourImage from '../assets/404.svg';
import './404.scss';

const FourOhFour = props => {
  const { location } = props;
  return (
    <Page location={location}>
      <div className="wrapper not-found-wrap">
        <img className="not-found-image" src={FourOhFourImage} alt="Opps this page has gone missing" />
        <div className="not-found-text">
          <h2>404 Not found</h2>
          <Link className="button" to="/">
            Go back home
          </Link>
        </div>
      </div>
    </Page>
  );
};

export default FourOhFour;
